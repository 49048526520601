import { render, staticRenderFns } from "./MyFooter.vue?vue&type=template&id=065db633&scoped=true&"
import script from "./MyFooter.vue?vue&type=script&lang=js&"
export * from "./MyFooter.vue?vue&type=script&lang=js&"
import style0 from "./MyFooter.vue?vue&type=style&index=0&id=065db633&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "065db633",
  null
  
)

export default component.exports