<template>
  <div>
    <b-container>
      <b-row align-h="center" v-if="false">
        <router-link :to="{ name: 'home' }">Templates</router-link>
        <router-link :to="{ name: 'home' }">Pricing</router-link>
        <router-link :to="{ name: 'home' }" href="#">Apps</router-link>
        <router-link :to="{ name: 'home' }">Blog</router-link>
        <router-link :to="{ name: 'home' }">About</router-link>
        <router-link :to="{ name: 'home' }">Help</router-link>
      </b-row>

      <b-row align-h="center" class="in-center">
        <p style="font-size: 0.8rem;margin: 0;color: #8c8c8c;">Copyright© 2019-2023 {{ appName }}{{ system === 0 ? "企业" :
          "" }}年报通
          All Rights Reserved.</p>
      </b-row>
      <b-row align-h="center" class="in-center">
        <!-- rgb(140, 140, 140) <p style="font-size: 0.8rem;color: #8c8c8c;">{{icp}} -->
        <a style="text-decoration: none;color: #007bff;;font-size: 12px;margin-bottom: 20px;"
          href="https://beian.miit.gov.cn" target="_blank" previewlistener="true">{{ icp }}</a>
        </p>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import appInfo, { system } from '../utils/config.js';
export default {
  name: "MyFooter",
  data: function () {
    return {
      system,
      appInfo,
    }
  },
  computed: {
    appName () {
      console.log("system", this.system);
      return this.appInfo[this.system].appName
    },
    telephone () {
      return this.appInfo[this.system].telephone
    },
    mpUrl () {
      return this.appInfo[this.system].mpUrl
    },
    company () {
      return this.appInfo[this.system].company
    },
    icp () {
      return this.appInfo[this.system].icp
    },
  },
};
</script>

<style lang="less" scoped>
a {
  width: auto !important;
  color: #7e7e7e;
  display: inline-block;
  text-decoration: underline;
  margin-right: 1rem;
}

p {
  width: auto !important;
}

a:hover,
a:active {
  color: #000000;
}
</style>